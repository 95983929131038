
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { RawMaterialClient } from '@/services/Services';

@Options({})
export default class EditFabricLayerModal extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() rawMaterials: OM.RawMaterialListVM[];
    @Prop() suggestedSQM: number;
    @Prop() saveCallback: (layer: OM.EditEstimationsGarmentLayersVM) => void;
    @Prop() isFirstLayer: boolean;
    @Prop() saveToGarmentConfiguration: boolean;

    @Prop({
        default: () => new OM.EditEstimationsGarmentLayersVM()
    }) editModel: OM.EditEstimationsGarmentLayersVM;
    localModel: OM.EditEstimationsGarmentLayersVM = new OM.EditEstimationsGarmentLayersVM();

    firstLayerAdded: boolean = false;
    firstLayerGrams: number = null;
    specificWeight: boolean = false;
    disablePercentage: boolean = false;

    tempFabricIdentifier: string = "";
    tempFabricPercentage: number = 0;
    tempSuggestedGrams: number = 0;
    tempGrams: number = 0;

    errorMessage: string = "";

    created() {
        this.editModel.identifier = this.estimationIdentifier;
        
        if(this.isFirstLayer) {
            this.editModel.sqm = this.suggestedSQM;
        }
        
        this.localModel.identifier = this.editModel.identifier;
        this.localModel.index = this.editModel.index;
        this.localModel.layerName = this.editModel.layerName;
        this.localModel.sqm = this.editModel.sqm;
        this.localModel.grams = this.editModel.grams;
        this.localModel.isFabric = this.editModel.isFabric;
        this.editModel.fabrics.forEach(element => {
            this.localModel.fabrics.push(element);
        });
    }

    get filteredRawMaterials(){
        var modelRawMaterials = this.localModel.fabrics.map( x => x.rawMaterial.identifier);

        return this.rawMaterials.filter( x => {
            if(modelRawMaterials.indexOf(x.identifier) == -1)
                return x;
        })
    }

    get remainingPercentage(){
        return 100 - this.localModel.fabrics.reduce((accumulator, fabric) => {
            return accumulator + fabric.percentage
        }, 0)
    }

    get adddisabled(){
        return !this.tempFabricIdentifier || !this.tempFabricPercentage || this.remainingPercentage == 0;
    }

    @Watch("tempFabricPercentage")
    @Watch("model.grams")
    @Watch("localModel.grams")
    updateSuggestedGrams() {
        if(!this.tempFabricIdentifier)
            return;

        var exist = this.rawMaterials.find(x => x.identifier == this.tempFabricIdentifier);
        this.tempSuggestedGrams = exist.suggestedGramsPerSquareMeters;

        if(this.gramsCustomized){
            this.tempGrams = this.localModel.grams / 100 * this.tempFabricPercentage;
        }
        else {
            if(!this.tempGrams) {
                this.tempGrams = this.tempSuggestedGrams;
            }

            if(this.tempSuggestedGrams != this.tempGrams)
                this.tempGrams = this.tempSuggestedGrams;
        }
    }
    // @Watch("tempFabricPercentage")
    // calculateGrams() {
    //     if(!this.tempFabricIdentifier)
    //         return;

    //     if(this.gramsCustomized){
    //         this.tempGrams = this.localModel.grams / 100 * this.tempFabricPercentage;
    //     }
    //     else {
    //         if(!this.tempGrams) {
    //             this.tempGrams = this.tempSuggestedGrams;
    //         }

    //         if(this.tempSuggestedGrams != this.tempGrams)
    //             this.tempGrams = this.tempSuggestedGrams;
    //     }
    // }

    addLayer(){
        if(this.remainingPercentage == 0)
            return;

        if(this.disablePercentage)
            this.tempFabricPercentage = 100;
            
        var fabriComposition = new OM.FabricComposition();

        var rawMaterial = this.rawMaterials.find( x => x.identifier == this.tempFabricIdentifier);
        fabriComposition.rawMaterial.identifier = rawMaterial.identifier;
        fabriComposition.rawMaterial.text = rawMaterial.text;

        if(this.tempFabricPercentage > this.remainingPercentage)
            this.tempFabricPercentage = this.remainingPercentage;

        if(typeof this.tempFabricPercentage == typeof "")
            fabriComposition.percentage = parseFloat(<any>this.tempFabricPercentage);
        else 
            fabriComposition.percentage = this.tempFabricPercentage;

        fabriComposition.sqm = this.localModel.sqm * this.tempFabricPercentage / 100;
        fabriComposition.suggestedGrams = this.tempSuggestedGrams;

        if(!this.firstLayerAdded){
            this.firstLayerAdded = true;

            if(this.gramsCustomized){
                this.firstLayerGrams = this.localModel.grams;
            }
        }
        
        fabriComposition.gramsPerSQM = this.tempGrams;
        this.localModel.fabrics.push(fabriComposition);

        this.tempFabricIdentifier = "";
        this.tempFabricPercentage = 0;
        this.tempSuggestedGrams = 0;
        this.tempGrams = 0;
    }

    removeFabric(index: number){
        this.localModel.fabrics.splice(index, 1);

        if(this.localModel.fabrics.length == 0){
            this.firstLayerAdded = false;
            this.firstLayerGrams = null;
        }
    }
    
    get disabled(){
        var sqm = this.localModel.sqm;

        if(typeof this.tempFabricPercentage == typeof "")
            sqm = parseFloat(<any>this.localModel.sqm);

        return !this.localModel.layerName && this.localModel.fabrics.length == 0 && !this.saveToGarmentConfiguration || !this.localModel.sqm || this.localModel.sqm <= 0;
    }

    save() {
        if(this.remainingPercentage > 0 && !this.saveToGarmentConfiguration)
            this.errorMessage = this.$localizationService.getLocalizedValue("Remaining percentage more than 0, please change your values", "Remaining percentage more than 0, please change your values");
        else
            this.saveCallback(this.localModel);
    }

    get gramsCustomized(){
        return this.localModel.grams && this.localModel.grams > 0 ;
    }

    clearSpecificWeight() {
        this.specificWeight = false;
        this.localModel.grams = null;
        this.tempGrams = this.tempSuggestedGrams;
    }

    gramsUpdated(value: number){
        if(value <= 0){
            value = null;
            var exist = this.rawMaterials.find(x => x.identifier == this.tempFabricIdentifier);
            this.tempSuggestedGrams = exist.suggestedGramsPerSquareMeters;
        }

        this.localModel.grams = value;
    }
}
